import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Axios from 'axios';
export function* changedSetting() {
  yield takeEvery('CHANGE_SETTING', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/set_settings_value', action.data);
      if (resp) {
        yield put({
          type: actions.CHANGE_SETTING_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_SETTING_ERROR });
    }
  
  
  });
}


export function* fetchSetting() {

  yield takeEvery('FETCH_SETTING', function* (action) {
    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/get_settings', action.data);
  
      if (resp) {
        yield put({
          type: actions.FETCH_SETTING_SUCCESS,
          data: resp.data.data,
  
        });
      }

    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });



}
export function* deleteSetting() {

yield takeEvery('DELETE_SETTING', function* (action) {
  try {

    const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/delete_settings_by_manager', action.data);
    if (resp) {
      yield put({
        type: actions.DELETE_SETTING_SUCCESS,
        data: action.data
      });
    }

  }
  catch (e) {
    yield put({ type: actions.DELETE_SETTING_ERROR });
  }


});
}


export function* deleteSettingSuccse() {

  yield takeEvery('DELETE_SETTING_SUCCESS', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/delete_settings_by_manager', action.data);
      if (resp) {
        yield put({
          type: actions.DELETE_SETTING_SUCCESS,
          data:  action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  
  
  });
  }
export default function* rootSaga() {
  yield all([fork(changedSetting), fork(fetchSetting),  fork(deleteSetting),


  ]);
}
