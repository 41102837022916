export default {
  apiUrl: 'https://ms-api.reezy.app/accounting/api/'
};
const siteConfig = {
  siteName: 'Reezy',
  siteIcon: 'ion-flash',
  footerText: 'Reezy ©2020 , Inc'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: 'https://ms-api.reezy.app/accounting/api/signIn'
    },
    languageDictionary: {
      title: 'Reezy',
      emailInputPlaceholder: 'demo@gmail.com',
      passwordInputPlaceholder: 'demodemo'
    },
    theme: {
      labeledSubmitButton: true,
      logo: '',
      primaryColor: '#2D5976',
      authButtons: {
        connectionName: {
          displayName: 'Log In',
          primaryColor: '#b7b7b7',
          foregroundColor: '#000000'
        }
      }
    }
  }
};
const firebaseConfig = {
  apiKey: '',
  authDomain: '',
  databaseURL: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: ''
};
const googleConfig = {
  apiKey: '' //
};
const mapboxConfig = {
  tileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  maxZoom: '18',
  defaultZoom: '12',
  center: [36.29793, 59.60023]
};
const youtubeSearchApi = '';


const currency = function number_3_3(num=0, sep) {
  if (num==null) num=0 
  num=faTOen(String(num))
  var number = typeof num === "number" ? num.toString() : num,
      separator = typeof sep === "undefined" ? ',' : sep;
  return number.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
}

const faTOen = function toEnglishDigits(str) {

  // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
  var e = '۰'.charCodeAt(0);
  str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
  });

  // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
  e = '٠'.charCodeAt(0);
  str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
  });
  return str;
}
export {
  currency,
  faTOen,
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};

