import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, LocaleProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig } from '../../settings';
import { AppLocale } from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import './global.css';
import userAction from "../../redux/users/actions.js";
import receiptAction from "../../redux/receipts/actions.js";
import productAction from "../../redux/products/actions.js";
import promotionAction from "../../redux/promotions/actions.js";
import settingAction from "../../redux/settings/actions.js";
import categoryActions from '../../redux/categorys/actions';
import storeAction from "../../redux/stores/actions.js";
const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;

const {
  fetchUser,

} = userAction;
const {
  fetchReceipt,
} = receiptAction;
const {
  fetchProduct
} = productAction;
const {
  fetchPromotion,
} = promotionAction;
const {
  fetchSetting
} = settingAction;
const {
  fetchStore
} = storeAction;
const {
  fetchCategory
} = categoryActions;

export class App extends Component {
state={
  body: {
    token: localStorage.getItem('id_token')
  },
  body2: {
    token: localStorage.getItem('id_token'),
    user_id: localStorage.getItem('user_id'),
  },
  isLoading: false
}
  componentDidMount(){
    const { fetchUser } = this.props;
    fetchUser(this.state.body)
    const { fetchReceipt } = this.props;
    fetchReceipt(this.state.body2)
    const { fetchProduct } = this.props;
    fetchProduct(this.state.body2)
    const { fetchPromotion } = this.props;
    fetchPromotion()
    const { fetchSetting } = this.props;
    fetchSetting(this.state.body2)
    const { fetchStore } = this.props;
    fetchStore(this.state.body2)
    const { fetchCategory } = this.props;
    fetchCategory(this.state.body2)
  }
  render() {
    const { url } = this.props.match;
    const { locale, selectedTheme, height } = this.props;
    const currentAppLocale = AppLocale[locale];
    const appHeight = window.innerHeight;
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ThemeProvider theme={themes[selectedTheme]}>
            <AppHolder>
              <Layout style={{ height: appHeight }}>
                <Debounce time="1000" handler="onResize">
                  <WindowResizeListener
                    onResize={windowSize =>
                      this.props.toggleAll(
                        windowSize.windowWidth,
                        windowSize.windowHeight
                      )
                    }
                  />
                </Debounce>
                <Topbar url={url} />
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                  <Sidebar url={url} />
                  <Layout
                    className="isoContentMainLayout"
                    style={{
                      height: height
                    }}
                  >
                    <Content
                      className="isomorphicContent"
                      style={{
                        padding: '70px 0 0',
                        flexShrink: '0',
                        background: '#f1f3f6',
                        position: 'relative'
                      }}
                    >
                      <AppRouter url={url} />
                    </Content>
                    <Footer
                      style={{
                        background: '#ffffff',
                        textAlign: 'center',
                        borderTop: '1px solid #ededed'
                      }}
                    >
                      {siteConfig.footerText}
                    </Footer>
                  </Layout>
                </Layout>
              
              </Layout>
            </AppHolder>
          </ThemeProvider>
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
    height: state.App.height,
    users: state.Users,
    receipts: state.Receipts,
    products: state.Products,
    promotions: state.Promotions,
    settings: state.Settings,
    stores: state.Stores,
    categorys:state.Categorys
  }),
  
  { logout, toggleAll, fetchUser ,fetchReceipt,fetchProduct,fetchPromotion,fetchSetting,fetchStore,fetchCategory}
)(App);
