const receiptActions = {
  CHANGE_RECRIPT: 'CHANGE_RECRIPT',
  CHANGE_RECRIPT_SUCCESS: 'CHANGE_RECRIPT_SUCCESS',
  CHANGE_RECRIPT_ERROR: 'CHANGE_RECRIPT_ERROR',
  ALL_COMPLETED: 'ALL_COMPLETED',
  DELETE_COMPLETED: 'DELETE_COMPLETED',
  FETCH_RECRIPT: 'FETCH_RECRIPT',
  FETCH_RECRIPT_SUCCESS: 'FETCH_RECRIPT_SUCCESS',
  FETCH_RECRIPT_ERROR: 'FETCH_RECRIPT_ERROR',
  DELETE_RECRIPT: 'DELETE_RECRIPT',
  DELETE_RECRIPT_SUCCESS: 'DELETE_RECRIPT_SUCCESS',
  DELETE_RECRIPT_ERROR: 'DELETE_RECRIPT_ERROR',
  fetchReceipt: (body) => ({
    type: receiptActions.FETCH_RECRIPT,
    data: body
  }),


  editReceipt: (body) => ({
    type: receiptActions.CHANGE_RECRIPT,
    data: body
  }),
  deleteReceipt: body => {

    return (dispatch, getState) => {
      const oldReceipts = getState().Receipts.receipts;
      const receipts = [];

      dispatch({
        type: receiptActions.DELETE_RECRIPT,
        data: body
      });
    };
  },
  allCompleted: () => {
    return (dispatch, getState) => {
      const oldReceipts = getState().Receipts.receipts;
      const receipts = [];
      oldReceipts.forEach(receipt => {
        receipt.completed = true;
        receipts.push(receipt);
      });
      dispatch({
        type: receiptActions.CHANGE_RECRIPT,
        receipts
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldReceipts = getState().Receipts.receipts;
      const receipts = [];
      oldReceipts.forEach(receipt => {
        if (!receipt.completed) {
          receipts.push(receipt);
        }
      });
      dispatch({
        type: receiptActions.DELETE_RECRIPT,
        receipts
      });
    };
  }
};
export default receiptActions;
