const storeActions = {
  CHANGE_STORE: 'CHANGE_STORE',
  CHANGE_STORE_SUCCESS: 'CHANGE_STORE_SUCCESS',
  CHANGE_STORE_ERROR: 'CHANGE_STORE_ERROR',
  ALL_COMPLETED: 'ALL_COMPLETED',
  DELETE_COMPLETED: 'DELETE_COMPLETED',
  FETCH_STORE: 'FETCH_STORE',
  FETCH_STORE_SUCCESS: 'FETCH_STORE_SUCCESS',
  FETCH_STORE_ERROR: 'FETCH_STORE_ERROR',
  DELETE_STORE: 'DELETE_STORE',
  DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
  DELETE_STORE_ERROR: 'DELETE_STORE_ERROR',
  ADD_STORE: 'ADD_STORE',
  ADD_STORE_SUCCESS: 'ADD_STORE_SUCCESS',
  ADD_STORE_ERROR: 'ADD_STORE_ERROR',
  
  fetchStore: (body) => ({
    type: storeActions.FETCH_STORE,
    data: body
  }),


  editStore: (body) => ({
    type: storeActions.CHANGE_STORE,
    data: body
  }),

  // addStore: (body) => (
  //   {
  //   type: storeActions.ALL_COMPLETED,
  //   data: body
  // }),

  addStore: body => {

    return (dispatch, getState) => {
      const oldStores = getState().Stores.stores;
      const stores = [];

      dispatch({
        type: storeActions.ADD_STORE,
        data: body
      });
    };
  },
  deleteStore: body => {

    return (dispatch, getState) => {
      const oldStores = getState().Stores.stores;
      const stores = [];

      dispatch({
        type: storeActions.DELETE_STORE,
        data: body
      });
    };
  },
  allCompleted: () => {
    return (dispatch, getState) => {
      const oldStores = getState().Stores.stores;
      const stores = [];
      oldStores.forEach(store => {
        store.completed = true;
        stores.push(store);
      });
      dispatch({
        type: storeActions.CHANGE_STORE,
        stores
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldStores = getState().Stores.stores;
      const stores = [];
      oldStores.forEach(store => {
        if (!store.completed) {
          stores.push(store);
        }
      });
      dispatch({
        type: storeActions.DELETE_STORE,
        stores
      });
    };
  }
};
export default storeActions;
