import React, { Component } from "react";
import Popconfirm from "../feedback/popconfirm";
import Button from "../../components/uielements/button";
export default class extends Component {
  render() {
    const { index, onDeleteCell } = this.props;
    return (
      <Popconfirm
        title="Sure to delete?"
        okText="DELETE"
        cancelText="No"
        onConfirm={() => onDeleteCell(index)}
      >
        <Button
          className="invoiceDltBtn"
          // icon="delete"
        >
          <i className="ion-android-delete" />
        </Button>
      </Popconfirm>
    );
  }
}
