import storeActions from './actions';

const stores = ""
const result = ""
const initState = {
  stores,
  result

};

export default function storeReducer(state = initState, action) {
  const stores = state.stores;
  const newstores = [];
  switch (action.type) {

    case storeActions.FETCH_STORE:
      return {
        ...state,
        result: ''
      };


    case storeActions.FETCH_STORE_SUCCESS:


      return {
        ...state,
        stores: action.data
      }




    case storeActions.DELETE_STORE:
      return {
        ...state
      };

    case storeActions.DELETE_STORE_SUCCESS:
      return {
        ...state, result: 'delete'
      };


    case storeActions.CHANGE_STORE:
      return {
        ...state
      };
    case storeActions.CHANGE_STORE_SUCCESS:
      return {
        ...state,
        result: "edit"
      };
    case storeActions.ADD_STORE:
      return {
        ...state
      };
    case storeActions.ADD_STORE_SUCCESS:
      return {
        ...state,
        result: "add"
      };


    case storeActions.ALL_COMPLETED:
      stores.forEach(store => {
        store.completed = true;
        newstores.push(store);
      });
      return {
        ...state,
        stores: newstores
      };
    case storeActions.DELETE_COMPLETED:
      stores.forEach(store => {
        if (store.completed !== true) {
          newstores.push(store);
        }
      });
      return {
        ...state,
        stores: newstores
      };
    default:
      return state;
  }
}
