import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import Axios from "axios";
export function* changedCategory() {
  yield takeEvery("CHANGE_CATEGORY", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/edit_category",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.CHANGE_CATEGORY_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_CATEGORY_ERROR });
    }
  });
}
export function* addCategory() {
  yield takeEvery("ADD_CATEGORY", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/add_category",
        action.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (resp.data.status != "error") {
        yield put({
          type: actions.ADD_CATEGORY_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_CATEGORY_ERROR });
    }
  });
}

export function* addCategoryRelation() {
  yield takeEvery("ADD_CATEGORY_RELATION", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/set_category_relation",
        action.data
      );

      if (resp.data.status != "error") {
        yield put({
          type: actions.ADD_CATEGORY_RELATION_SUCCESS,
          data: action.data,
        });
      } else {
        yield put({ type: actions.CHANGE_CATEGORY_ERROR });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_CATEGORY_ERROR });
    }
  });
}

export function* addCategoryRelations() {
  yield takeEvery("ADD_CATEGORY_RELATIONS", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/set_category_relations",
        action.data
      );

      if (resp.data.status != "error") {
        yield put({
          type: actions.ADD_CATEGORY_RELATIONS_SUCCESS,
          data: action.data,
        });
      } else {
        yield put({ type: actions.CHANGE_CATEGORY_ERROR });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_CATEGORY_ERROR });
    }
  });
}

export function* fetchCategory() {
  yield takeEvery("FETCH_CATEGORY", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/get_categories",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.FETCH_CATEGORY_SUCCESS,
          data: resp.data.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}
export function* fetchCategoryًRelation() {
  yield takeEvery("FETCH_CATEGORY_RELATION", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/get_category_relation",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.FETCH_CATEGORY_RELATION_SUCCESS,
          data: resp.data.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* deleteCategoryRelation() {
  yield takeEvery("DELETE_CATEGORY_RELATION", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/delete_category_relation",
        action.data
      );
      if (resp.data.status != "error") {
        yield put({
          type: actions.DELETE_CATEGORY_RELATION_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.DELETE_CATEGORY_RELATION_ERROR });
    }
  });
}

export function* deleteCategory() {
  yield takeEvery("DELETE_CATEGORY", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/delete_category",
        action.data
      );
      if (resp.data.status != "error") {
        yield put({
          type: actions.DELETE_CATEGORY_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.DELETE_CATEGORY_ERROR });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCategoryًRelation),
    fork(changedCategory),
    fork(fetchCategory),
    fork(deleteCategory),
    fork(addCategory),
    fork(addCategoryRelation),
    fork(addCategoryRelations),
    fork(deleteCategoryRelation),
  ]);
}
