import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Axios from 'axios';
export function* changedPromotion() {
  yield takeEvery('CHANGE_PROMOITON', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/promotions/api/editPromotion', action.data);
      if (resp) {
        yield put({
          type: actions.CHANGE_PROMOITON_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_PROMOITON_ERROR });
    }
  
  
  });
}
export function* addPromotion() {

  yield takeEvery('ADD_PROMOITON', function* (action) {

    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/promotions/api/addPromotion', action.data,      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (resp.data.status!="error") {
        yield put({
          type: actions.ADD_PROMOITON_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_PROMOITON_ERROR });
    }
  
  
  });
}



export function* fetchPromotion() {

  yield takeEvery('FETCH_PROMOITON', function* () {
    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/promotions/api/searchPromotion');
      if (resp) {
        yield put({
          type: actions.FETCH_PROMOITON_SUCCESS,
          data: resp.data.data
        });
      }

    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });
}


export function* deletePromotion() {

yield takeEvery('DELETE_PROMOITON', function* (action) {
  try {

    const resp = yield call(Axios.post, 'https://ms-api.reezy.app/promotions/api/deletePromotion', action.data);
    if (resp.data.status!="error") {
      yield put({
        type: actions.DELETE_PROMOITON_SUCCESS,
        data: action.data
      });
    }

  }
  catch (e) {
    yield put({ type: actions.DELETE_PROMOITON_ERROR });
  }


});
}


export default function* rootSaga() {
  yield all([fork(changedPromotion), fork(fetchPromotion),  fork(deletePromotion),fork(addPromotion)


  ]);
}
