import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import Axios from "axios";
export function* changedProduct() {
  yield takeEvery(actions.CHANGE_PRODUCT, function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/edit_product_by_manager",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.CHANGE_PRODUCT_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_RECRIPT_ERROR });
    }
  });
}

export function* addProductCategories() {
  yield takeEvery(actions.ADD_PRODUCT_CATEGORIES, function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/add_product_categories",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.ADD_PRODUCT_CATEGORIES_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.CHANGE_RECRIPT_ERROR });
    }
  });
}

export function* fetchProduct() {
  yield takeEvery("FETCH_PRODUCT", function* (action) {
    try {
      const resp1 = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/get_all_products_by_manager",
        action.data
      );
      //const resp2 = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/userLists', action.data);

      if (resp1) {
        yield put({
          type: actions.FETCH_PRODUCT_SUCCESS,
          data: resp1.data.data,
          // data2:resp2.data.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}
export function* deleteProduct() {
  yield takeEvery("DELETE_PRODUCT", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/delete_products_by_manager",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.DELETE_PRODUCT_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.DELETE_PRODUCT_ERROR });
    }
  });
}

export function* deleteProductSuccse() {
  yield takeEvery("DELETE_PRODUCT_SUCCESS", function* (action) {
    try {
      const resp = yield call(
        Axios.post,
        "https://ms-api.reezy.app/receipts/api/delete_products_by_manager",
        action.data
      );
      if (resp) {
        yield put({
          type: actions.DELETE_PRODUCT_SUCCESS,
          data: action.data,
        });
      }
    } catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(changedProduct),
    fork(addProductCategories),
    fork(fetchProduct),
    fork(deleteProduct),
  ]);
}
