import promotionActions from './actions';

const promotions = ""
const result = ""
const initState = {
  promotions,
  result

};

export default function promotionReducer(state = initState, action) {
  const promotions = state.promotions;
  const newpromotions = [];
  switch (action.type) {

    case promotionActions.FETCH_PROMOITON:
      return {
        ...state,
        result: ''
      };


    case promotionActions.FETCH_PROMOITON_SUCCESS:


      return {
        ...state,
        promotions: action.data
      }




    case promotionActions.DELETE_PROMOITON:
      return {
        ...state
      };

    case promotionActions.DELETE_PROMOITON_SUCCESS:
      return {
        ...state, result: 'delete'
      };


    case promotionActions.CHANGE_PROMOITON:
      return {
        ...state
      };
    case promotionActions.CHANGE_PROMOITON_SUCCESS:
      return {
        ...state,
        result: "edit"
      };
    case promotionActions.ADD_PROMOITON:
      return {
        ...state
      };
    case promotionActions.ADD_PROMOITON_SUCCESS:
      return {
        ...state,
        result: "add"
      };


    case promotionActions.ALL_COMPLETED:
      promotions.forEach(promotion => {
        promotion.completed = true;
        newpromotions.push(promotion);
      });
      return {
        ...state,
        promotions: newpromotions
      };
    case promotionActions.DELETE_COMPLETED:
      promotions.forEach(promotion => {
        if (promotion.completed !== true) {
          newpromotions.push(promotion);
        }
      });
      return {
        ...state,
        promotions: newpromotions
      };
    default:
      return state;
  }
}
