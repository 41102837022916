import userActions from './actions';

const users = ""
const error = ""
const result = ""
const initState = {
  users,
  error,
  result

};

export default function userReducer(state = initState, action) {
  const users = state.users;
  const newusers = [];

  switch (action.type) {
    case userActions.CHANGE_USER:
      return {
        ...state
      };
      case userActions.CHANGE_USER_SUCCESS:
        return {
          ...state,
          result:"edit"
        };
    case userActions.FETCH_USER:
      return {
        ...state, result: "",error: ""
      };
    case userActions.FETCH_USER_SUCCESS:

      return {
        ...state,
        users: action.data,


      };
    case userActions.FETCH_USER_ERROR:

      return {
        ...state,
        error: 122,

      };
  
  
      case userActions.DELETE_USER:
        return {
          ...state
        };
  
      case userActions.DELETE_USER_SUCCESS:
        return {
          ...state,     result:'delete'
        };
  
      default:
      return state;
  }
}
