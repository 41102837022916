import React, { Component } from 'react';
import Input from '../uielements/input';
import { Icon } from 'antd';
import moment from "moment";
import { DatePicker, version } from "antd";
export default class extends Component {
  state = {
    value: this.props.value,
    editable: false
  };
  handleChange = (date, dateString) => {

    const value = dateString;
    this.setState({ value });
  };

  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(
        this.state.value,
        this.props.columnsKey,
        this.props.index
      );
    }
  };
  edit = () => {
    this.setState({ editable: true });
  };
  render() {
    const { value, editable } = this.state;
    return (
      <div className="isoEditData">
        {editable ? (
          <div className="isoEditDataWrapper">
            {/* <Input
              value={value}
              onChange={this.handleChange}
              onPressEnter={this.check}
            /> */}

            <DatePicker defaultValue={moment()}
              onChange={this.handleChange} />
            <Icon type="check" className="isoEditIcon" onClick={this.check} />
          </div>
        ) : (
            <p className="isoDataWrapper">
              {value || ' '}
              <Icon type="edit" className="isoEditIcon" onClick={this.edit} />
            </p>
          )}
      </div>
    );
  }
}
