import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken } from '../../helpers/utility';
import actions from './actions';
import Axios from 'axios';

export function* loginRequest() {

  yield takeEvery('LOGIN_REQUEST', function* (action) {

    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/signIn', action.data)

      if (resp.data.status == "success") {

        yield put({
          type: actions.LOGIN_SUCCESS,
          token: resp.data.data.token,
          user_id: resp.data.data.user_id,
          image: resp.data.data.image,
          profile: 'Profile'
        });
      } else {

        yield put({ type: actions.LOGIN_ERROR });
      }
    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    if (payload.user_id != undefined) {
      yield localStorage.setItem('id_token', payload.token);
      yield localStorage.setItem('user_id', payload.user_id);
      yield localStorage.setItem('image', payload.image);
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken();
    yield put(push('/'));
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    const user = getToken().get('idUser');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        user,
        profile: 'Profile'
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout)
  ]);
}
