import productActions from "./actions";

const colors = ["#7ED321", "#de1b1b", "#511E78", "#ff9009", "#42a5f5"];
const products = "";
const users = "";
const result = "";
const isLoading = false;
const initState = {
  products,
  users,
  result,
  colors,
  isLoading,
};

export default function productReducer(state = initState, action) {
  const products = state.products;
  const users = state.users;
  const newproducts = [];
  const newusers = [];
  switch (action.type) {
    case productActions.CHANGE_PRODUCT:
      return {
        ...state,
        products: action.products,
      };
    case productActions.FETCH_PRODUCT:
      return {
        ...state,
        result: "",
        isLoading: true,
      };

    case productActions.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.data,
        isLoading: false,
      };

    case productActions.DELETE_PRODUCT:
      return {
        ...state,
      };

    case productActions.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter((i) => i.id !== action.data.factor_id),
      };
    case productActions.ALL_COMPLETED:
      products.forEach((product) => {
        product.completed = true;
        newproducts.push(product);
      });
      return {
        ...state,
        products: newproducts,
      };
    case productActions.DELETE_COMPLETED:
      products.forEach((product) => {
        if (product.completed !== true) {
          newproducts.push(product);
        }
      });

    case productActions.CHANGE_PRODUCT:
      return {
        ...state,
      };
    case productActions.CHANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        result: "edit",
      };

    case productActions.ADD_PRODUCT_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      };

    case productActions.ADD_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        result: "edit",
        isLoading: false,
      };

    default:
      return state;
  }
}
