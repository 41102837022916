import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Axios from 'axios';


export function* changedUser() {
  yield takeEvery('CHANGE_USER', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/disableUser', action.data);
      if (resp) {
        yield put({
          type: actions.CHANGE_USER_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE__ERROR });
    }
  
  
  });
}
export function* fetchUser() {

  yield takeEvery('FETCH_USER', function* (action) {
    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/userLists', action.data);

      if (resp.data.err.msg) {
        yield put({ type: actions.FETCH_USER_ERROR });
      }
      if (resp) {
        yield put({
          type: actions.FETCH_USER_SUCCESS,
          data: resp.data.data,
      
        });
      }

    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });
}

export function* deleteUser() {

  yield takeEvery('DELETE_USER', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/deleteUser', action.data);
      if (resp) {
        yield put({
          type: actions.DELETE_USER_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.DELETE_USER_ERROR });
    }
  
  
  });
  }
export default function* rootSaga() {
  yield all([fork(changedUser), fork(fetchUser),fork(deleteUser)


  ]);
}
