const categoryActions = {
  CHANGE_CATEGORY: "CHANGE_CATEGORY",
  CHANGE_CATEGORY_SUCCESS: "CHANGE_CATEGORY_SUCCESS",
  CHANGE_CATEGORY_ERROR: "CHANGE_CATEGORY_ERROR",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COMPLETED: "DELETE_COMPLETED",
  FETCH_CATEGORY: "FETCH_CATEGORY",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  FETCH_CATEGORY_ERROR: "FETCH_CATEGORY_ERROR",
  FETCH_CATEGORY_RELATION: "FETCH_CATEGORY_RELATION",
  FETCH_CATEGORY_RELATION_SUCCESS: "FETCH_CATEGORY_RELATION_SUCCESS",
  FETCH_CATEGORY_RELATION_ERROR: "FETCH_CATEGORY_RELATION_ERROR",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR",
  DELETE_CATEGORY_RELATION: "DELETE_CATEGORY_RELATION",
  DELETE_CATEGORY_RELATION_SUCCESS: "DELETE_CATEGORY_RELATION_SUCCESS",
  DELETE_CATEGORY_RELATION_ERROR: "DELETE_CATEGORY_RELATION_ERROR",
  ADD_CATEGORY_RELATION: "ADD_CATEGORY_RELATION",
  ADD_CATEGORY_RELATION_SUCCESS: "ADD_CATEGORY_RELATION_SUCCESS",
  ADD_CATEGORY_RELATIONS: "ADD_CATEGORY_RELATIONS",
  ADD_CATEGORY_RELATIONS_SUCCESS: "ADD_CATEGORY_RELATIONS_SUCCESS",
  ADD_CATEGORY_RELATION_ERROR: "ADD_CATEGORY_RELATION_ERROR",
  ADD_CATEGORY: "ADD_CATEGORY",
  ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
  ADD_CATEGORY_ERROR: "ADD_CATEGORY_ERROR",

  fetchCategory: (body) => ({
    type: categoryActions.FETCH_CATEGORY,
    data: body,
  }),
  fetchCategoryRelation: (body) => ({
    type: categoryActions.FETCH_CATEGORY_RELATION,
    data: body,
  }),

  editCategory: (body) => ({
    type: categoryActions.CHANGE_CATEGORY,
    data: body,
  }),

  // addCategory: (body) => (
  //   {
  //   type: categoryActions.ALL_COMPLETED,
  //   data: body
  // }),

  addCategory: (body) => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];

      dispatch({
        type: categoryActions.ADD_CATEGORY,
        data: body,
      });
    };
  },
  deleteCategory: (body) => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];

      dispatch({
        type: categoryActions.DELETE_CATEGORY,
        data: body,
      });
    };
  },

  addCategoryRelation: (body) => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];

      dispatch({
        type: categoryActions.ADD_CATEGORY_RELATION,
        data: body,
      });
    };
  },

  addCategoryRelations: (body) => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];

      dispatch({
        type: categoryActions.ADD_CATEGORY_RELATIONS,
        data: body,
      });
    };
  },

  deleteCategoryRelation: (body) => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];

      dispatch({
        type: categoryActions.DELETE_CATEGORY_RELATION,
        data: body,
      });
    };
  },

  allCompleted: () => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];
      oldCategorys.forEach((category) => {
        category.completed = true;
        categorys.push(category);
      });
      dispatch({
        type: categoryActions.CHANGE_CATEGORY,
        categorys,
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldCategorys = getState().Categorys.categorys;
      const categorys = [];
      oldCategorys.forEach((category) => {
        if (!category.completed) {
          categorys.push(category);
        }
      });
      dispatch({
        type: categoryActions.DELETE_CATEGORY,
        categorys,
      });
    };
  },
};

export default categoryActions;
