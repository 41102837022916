const productActions = {
  CHANGE_PRODUCT: "CHANGE_PRODUCT",
  CHANGE_PRODUCT_SUCCESS: "CHANGE_PRODUCT_SUCCESS",
  ALL_COMPLETED: "ALL_COMPLETED",
  DELETE_COMPLETED: "DELETE_COMPLETED",
  FETCH_PRODUCT: "FETCH_PRODUCT",
  FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_ERROR: "FETCH_PRODUCT_ERROR",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_ERROR: "DELETE_PRODUCT_ERROR",
  ADD_PRODUCT_CATEGORIES: "ADD_PRODUCT_CATEGORIES",
  ADD_PRODUCT_CATEGORIES_SUCCESS: "ADD_PRODUCT_CATEGORIES_SUCCESS",
  fetchProduct: (body) => ({
    type: productActions.FETCH_PRODUCT,
    data: body,
  }),

  addProduct: (product) => {
    return (dispatch, getState) => {
      const newProduct = {
        id: new Date(),
        product: product,
        createTime: new Date(),
        color: 0,
        completed: false,
      };
      const products = [newProduct, ...getState().Products.products];
      dispatch({
        type: productActions.CHANGE_PRODUCT,
        products,
      });
    };
  },

  editproduct: (body) => ({
    type: productActions.CHANGE_PRODUCT,
    data: body,
  }),

  addProductCategories: (body) => ({
    type: productActions.ADD_PRODUCT_CATEGORIES,
    data: body,
  }),

  deleteProduct: (body) => {
    return (dispatch, getState) => {
      const oldProducts = getState().Products.products;
      const products = [];

      dispatch({
        type: productActions.DELETE_PRODUCT,
        data: body,
      });
    };
  },
  allCompleted: () => {
    return (dispatch, getState) => {
      const oldProducts = getState().Products.products;
      const products = [];
      oldProducts.forEach((product) => {
        product.completed = true;
        products.push(product);
      });
      dispatch({
        type: productActions.CHANGE_PRODUCT,
        products,
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldProducts = getState().Products.products;
      const products = [];
      oldProducts.forEach((product) => {
        if (!product.completed) {
          products.push(product);
        }
      });
      dispatch({
        type: productActions.DELETE_PRODUCT,
        products,
      });
    };
  },
};
export default productActions;
