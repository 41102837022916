import actions from "./actions";
const result = ""
const initState = { idToken: null,result };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        isLoggedIn: true
      };
      
      case actions.LOGIN_REQUEST:
        return {
          idToken: null ,
          result: '',
          isLoggedIn: false
        };
    case actions.LOGIN_ERROR:
      return {
        idToken: null ,
        result: 'error',
        isLoggedIn: false
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
