import receiptActions from './actions';

const receipts = ""
const users = ""
const result = ""
const initState = {
  receipts,
  users,
 
  result

};

export default function receiptReducer(state = initState, action) {
  const receipts = state.receipts;
  const users = state.users;
  const newreceipts = [];
  const newusers = [];
  switch (action.type) {

    case receiptActions.FETCH_RECRIPT:
      return {
        ...state,
        result:''
      };


    case receiptActions.FETCH_RECRIPT_SUCCESS:


      return {
        ...state, receipts: action.data
      }




    case receiptActions.DELETE_RECRIPT:
      return {
        ...state
      };

    case receiptActions.DELETE_RECRIPT_SUCCESS:
      return {
        ...state,     result:'delete'
      };


    case receiptActions.CHANGE_RECRIPT:
      return {
        ...state
      };
    case receiptActions.CHANGE_RECRIPT_SUCCESS:
      return {
        ...state,
        result:"edit"
      };

    case receiptActions.ALL_COMPLETED:
      receipts.forEach(receipt => {
        receipt.completed = true;
        newreceipts.push(receipt);
      });
      return {
        ...state,
        receipts: newreceipts
      };
    case receiptActions.DELETE_COMPLETED:
      receipts.forEach(receipt => {
        if (receipt.completed !== true) {
          newreceipts.push(receipt);
        }
      });
      return {
        ...state,
        receipts: newreceipts
      };
    default:
      return state;
  }
}
