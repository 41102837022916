const settingActions = {
  CHANGE_SETTING: 'CHANGE_SETTING',
  CHANGE_SETTING_SUCCESS: 'CHANGE_SETTING_SUCCESS',
  CHANGE_SETTING_ERROR: 'CHANGE_SETTING_ERROR',
  ALL_COMPLETED: 'ALL_COMPLETED',
  DELETE_COMPLETED: 'DELETE_COMPLETED',
  FETCH_SETTING: 'FETCH_SETTING',
  FETCH_SETTING_SUCCESS: 'FETCH_SETTING_SUCCESS',
  FETCH_SETTING_ERROR: 'FETCH_SETTING_ERROR',
  DELETE_SETTING: 'DELETE_SETTING',
  DELETE_SETTING_SUCCESS: 'DELETE_SETTING_SUCCESS',
  DELETE_SETTING_ERROR: 'DELETE_SETTING_ERROR',
  fetchSetting: (body) => ({
    type: settingActions.FETCH_SETTING,
    data: body
  }),


  editSetting: (body) => ({
    type: settingActions.CHANGE_SETTING,
    data: body
  }),
  deleteSetting: body => {

    return (dispatch, getState) => {
      const oldSettings = getState().Settings.settings;
      const settings = [];

      dispatch({
        type: settingActions.DELETE_SETTING,
        data: body
      });
    };
  },
  allCompleted: () => {
    return (dispatch, getState) => {
      const oldSettings = getState().Settings.settings;
      const settings = [];
      oldSettings.forEach(setting => {
        setting.completed = true;
        settings.push(setting);
      });
      dispatch({
        type: settingActions.CHANGE_SETTING,
        settings
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldSettings = getState().Settings.settings;
      const settings = [];
      oldSettings.forEach(setting => {
        if (!setting.completed) {
          settings.push(setting);
        }
      });
      dispatch({
        type: settingActions.DELETE_SETTING,
        settings
      });
    };
  }
};
export default settingActions;
