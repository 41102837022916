import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Axios from 'axios';
export function* changedReceipt() {
  yield takeEvery('CHANGE_RECRIPT', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/edit_receipts_by_manager', action.data);
      if (resp) {
        yield put({
          type: actions.CHANGE_RECRIPT_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_RECRIPT_ERROR });
    }
  
  
  });
}


export function* fetchReceipt() {

  yield takeEvery('FETCH_RECRIPT', function* (action) {
    try {

      const resp1 = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/get_receipts_by_manager', action.data);
    //  const resp2 = yield call(Axios.post, 'https://ms-api.reezy.app/accounting/api/userLists', action.data);

      if (resp1) {
        yield put({
          type: actions.FETCH_RECRIPT_SUCCESS,
          data: resp1.data.data,
        //  data2:resp2.data.data,
        });
      }

    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });



}
export function* deleteReceipt() {

yield takeEvery('DELETE_RECRIPT', function* (action) {
  try {

    const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/delete_receipts_by_manager', action.data);
    if (resp) {
      yield put({
        type: actions.DELETE_RECRIPT_SUCCESS,
        data: action.data
      });
    }

  }
  catch (e) {
    yield put({ type: actions.DELETE_RECRIPT_ERROR });
  }


});
}


export function* deleteReceiptSuccse() {

  yield takeEvery('DELETE_RECRIPT_SUCCESS', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/delete_receipts_by_manager', action.data);
      if (resp) {
        yield put({
          type: actions.DELETE_RECRIPT_SUCCESS,
          data:  action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }
  
  
  });
  }
export default function* rootSaga() {
  yield all([fork(changedReceipt), fork(fetchReceipt),  fork(deleteReceipt),


  ]);
}
