import categoryActions from "./actions";
const categorys = "";
const categorysModel = "";
const categorys_relation = "";
const result = "";
const initState = {
  categorys,
  categorysModel,
  result,
  isLoading: false,
  categorys_relation,
};

export default function categoryReducer(state = initState, action) {
  const categorys = state.categorys;
  const newcategorys = [];
  switch (action.type) {
    case categoryActions.FETCH_CATEGORY:
      return {
        ...state,
        isLoading: true,
        result: "",
      };

    case categoryActions.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categorys: action.data,
        categorysModel: action.data,
      };

    case categoryActions.FETCH_CATEGORY_RELATION:
      return {
        ...state,
        isLoading: true,
        categorys_relation: false,
        result: "",
      };
    case categoryActions.FETCH_CATEGORY_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categorys_relation: action.data,
        result: "",
      };

    case categoryActions.DELETE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };

    case categoryActions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "delete",
      };

    case categoryActions.CHANGE_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case categoryActions.CHANGE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "edit",
      };
    case categoryActions.ADD_CATEGORY:
      return {
        ...state,
        isLoading: true,
      };
    case categoryActions.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "add",
      };

    case categoryActions.DELETE_CATEGORY_RELATION:
      return {
        ...state,
        isLoading: true,
      };

    case categoryActions.DELETE_CATEGORY_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "delete_rel",
      };

    case categoryActions.ADD_CATEGORY_RELATION:
    case categoryActions.ADD_CATEGORY_RELATIONS:
      return {
        ...state,
        isLoading: true,
      };

    case categoryActions.ADD_CATEGORY_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "add_rel",
      };

    case categoryActions.ADD_CATEGORY_RELATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: "add_relations",
      };

    case categoryActions.CHANGE_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        result: "",
      };

    case categoryActions.ALL_COMPLETED:
      categorys.forEach((category) => {
        category.completed = true;
        newcategorys.push(category);
      });
      return {
        ...state,
        categorys: newcategorys,
      };
    case categoryActions.DELETE_COMPLETED:
      categorys.forEach((category) => {
        if (category.completed !== true) {
          newcategorys.push(category);
        }
      });
      return {
        ...state,
        categorys: newcategorys,
      };
    default:
      return state;
  }
}
