const userActions = {
  CHANGE_USER: 'CHANGE_USER',
  ALL_COMPLETED: 'ALL_COMPLETED',
  DELETE_COMPLETED: 'DELETE_COMPLETED',
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  CHANGE_USER: 'CHANGE_USER',
  CHANGE_USER_SUCCESS: 'CHANGE_USER_SUCCESS',
  CHANGE_USER_ERROR: 'CHANGE_USER_ERROR',
  fetchUser: (body) => ({
    type: userActions.FETCH_USER,
    data: body
  }),


  edituser: (body) => ({
    type: userActions.CHANGE_USER,
    data: body
  }),
  deleteUser: body => {

    return (dispatch, getState) => {
      const oldUsers = getState().Users.Users;
      const Users = [];

      dispatch({
        type: userActions.DELETE_USER,
        data: body
      });
    };
  },
};
export default userActions;
