const promotionActions = {
  CHANGE_PROMOITON: 'CHANGE_PROMOITON',
  CHANGE_PROMOITON_SUCCESS: 'CHANGE_PROMOITON_SUCCESS',
  CHANGE_PROMOITON_ERROR: 'CHANGE_PROMOITON_ERROR',
  ALL_COMPLETED: 'ALL_COMPLETED',
  DELETE_COMPLETED: 'DELETE_COMPLETED',
  FETCH_PROMOITON: 'FETCH_PROMOITON',
  FETCH_PROMOITON_SUCCESS: 'FETCH_PROMOITON_SUCCESS',
  FETCH_PROMOITON_ERROR: 'FETCH_PROMOITON_ERROR',
  DELETE_PROMOITON: 'DELETE_PROMOITON',
  DELETE_PROMOITON_SUCCESS: 'DELETE_PROMOITON_SUCCESS',
  DELETE_PROMOITON_ERROR: 'DELETE_PROMOITON_ERROR',
  ADD_PROMOITON: 'ADD_PROMOITON',
  ADD_PROMOITON_SUCCESS: 'ADD_PROMOITON_SUCCESS',
  ADD_PROMOITON_ERROR: 'ADD_PROMOITON_ERROR',
  
  fetchPromotion: () => ({
    type: promotionActions.FETCH_PROMOITON
  }),


  editPromotion: (body) => ({
    type: promotionActions.CHANGE_PROMOITON,
    data: body
  }),

  // addPromotion: (body) => (
  //   {
  //   type: promotionActions.ALL_COMPLETED,
  //   data: body
  // }),

  addPromotion: body => {

    return (dispatch, getState) => {
      const oldPromotions = getState().Promotions.promotions;
      const promotions = [];

      dispatch({
        type: promotionActions.ADD_PROMOITON,
        data: body
      });
    };
  },
  deletePromotion: body => {

    return (dispatch, getState) => {
      const oldPromotions = getState().Promotions.promotions;
      const promotions = [];

      dispatch({
        type: promotionActions.DELETE_PROMOITON,
        data: body
      });
    };
  },
  allCompleted: () => {
    return (dispatch, getState) => {
      const oldPromotions = getState().Promotions.promotions;
      const promotions = [];
      oldPromotions.forEach(promotion => {
        promotion.completed = true;
        promotions.push(promotion);
      });
      dispatch({
        type: promotionActions.CHANGE_PROMOITON,
        promotions
      });
    };
  },
  deleteCompleted: () => {
    return (dispatch, getState) => {
      const oldPromotions = getState().Promotions.promotions;
      const promotions = [];
      oldPromotions.forEach(promotion => {
        if (!promotion.completed) {
          promotions.push(promotion);
        }
      });
      dispatch({
        type: promotionActions.DELETE_PROMOITON,
        promotions
      });
    };
  }
};
export default promotionActions;
