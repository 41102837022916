import settingActions from './actions';

const settings = ""
const users = ""
const result = ""
const initState = {
  settings,
  users,
 
  result

};

export default function settingReducer(state = initState, action) {
  const settings = state.settings;
  const users = state.users;
  const newsettings = [];
  const newusers = [];
  switch (action.type) {

    case settingActions.FETCH_SETTING:
      return {
        ...state,
        result:''
      };


    case settingActions.FETCH_SETTING_SUCCESS:


      return {
        ...state, settings: action.data
      }




    case settingActions.DELETE_SETTING:
      return {
        ...state
      };

    case settingActions.DELETE_SETTING_SUCCESS:
      return {
        ...state,     result:'delete'
      };


    case settingActions.CHANGE_SETTING:
      return {
        ...state
      };
    case settingActions.CHANGE_SETTING_SUCCESS:
      return {
        ...state,
        result:"edit"
      };

    case settingActions.ALL_COMPLETED:
      settings.forEach(setting => {
        setting.completed = true;
        newsettings.push(setting);
      });
      return {
        ...state,
        settings: newsettings
      };
    case settingActions.DELETE_COMPLETED:
      settings.forEach(setting => {
        if (setting.completed !== true) {
          newsettings.push(setting);
        }
      });
      return {
        ...state,
        settings: newsettings
      };
    default:
      return state;
  }
}
