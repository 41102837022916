import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Axios from 'axios';
export function* changedStore() {
  yield takeEvery('CHANGE_STORE', function* (action) {
    try {
  
      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/edit_store', action.data);
      if (resp) {
        yield put({
          type: actions.CHANGE_STORE_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_STORE_ERROR });
    }
  
  
  });
}
export function* addStore() {

  yield takeEvery('ADD_STORE', function* (action) {

    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/add_store', action.data,      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (resp.data.status!="error") {
        yield put({
          type: actions.ADD_STORE_SUCCESS,
          data: action.data
        });
      }
  
    }
    catch (e) {
      yield put({ type: actions.CHANGE_STORE_ERROR });
    }
  
  
  });
}



export function* fetchStore() {

  yield takeEvery('FETCH_STORE', function* (action) {
    try {

      const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/get_stores' ,action.data);
      if (resp) {
        yield put({
          type: actions.FETCH_STORE_SUCCESS,
          data: resp.data.data
        });
      }

    }
    catch (e) {
      yield put({ type: actions.LOGIN_ERROR });
    }


  });
}


export function* deleteStore() {

yield takeEvery('DELETE_STORE', function* (action) {
  try {

    const resp = yield call(Axios.post, 'https://ms-api.reezy.app/receipts/api/delete_store', action.data);
    if (resp.data.status!="error") {
      yield put({
        type: actions.DELETE_STORE_SUCCESS,
        data: action.data
      });
    }

  }
  catch (e) {
    yield put({ type: actions.DELETE_STORE_ERROR });
  }


});
}


export default function* rootSaga() {
  yield all([fork(changedStore), fork(fetchStore),  fork(deleteStore),fork(addStore)


  ]);
}
